<template>
  <div class="login" id="login">
    <div class="login-title">
      用户登录
    </div>
    <van-form @submit="onSubmit">
      <div class="login-input">
        <p class="input-title">
          用户名
        </p>
        <van-field v-model="username" name="username" placeholder="请输入用户名" clearable :rules="[{ required: true, message: '请填写用户名' }]" />
        <div class="center-line"></div>
        <p class="input-title">
          密码
        </p>
        <van-field v-model="password" name="username" :type="passwordType" placeholder="请输入密码" clearable :rules="[{ required: true, message: '请填写密码' }]">
          <template #right-icon>
            <van-image width="25" height="25" v-if="showEye" @click="disPassword('open')" :src="require('@/assets/images/eye.png')" />
            <van-image width="25" height="25" v-else @click="disPassword('close')" :src="require('@/assets/images/openEye.png')" />
          </template>
        </van-field>
        <div class="bottom-line"></div>
      </div>
      <p class="forget-password">
        <span @click="forget">忘记密码</span>
      </p>
      <div class="login-btn">
        <van-button round block type="info" color="#15AEA2" native-type="submit">登录</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { request } from "@/api/service";
import { Dialog } from "vant";
import getUserInfo from "@/api/common/getUserInfo";
import store from "@/store";
export default {
  name: "ProductIndex",
  components: {},
  data() {
    return {
      username: "",
      password: "",
      passwordType: "password",
      showEye: true
    };
  },
  computed: {
    ...mapState("user", ["info"])
  },
  mounted() {
    // eslint-disable-next-line no-undef
    login.style.height = document.documentElement.clientHeight + "px";
  },
  methods: {
    onSubmit() {
      request({
        url: "/afis-auth/auth/app/dealer/login",
        method: "post",
        data: {
          username: this.username,
          password: this.password,
          thirdId: (this.info && this.info.thirdId) || (this.$route.query && this.$route.query.thirdId)
        }
      }).then(res => {
        store.dispatch("user/set", res);
        store.dispatch("dict/setDict");
        getUserInfo();
        this.$router.replace({
          path: "/product"
        });
      });
    },
    forget() {
      Dialog.alert({
        title: "",
        message: "请联系管理员进行重置，重置密码后再次登录"
      }).then(() => {});
    },
    disPassword(type) {
      this.showEye = type === "open" ? false : true;
      this.passwordType = type === "open" ? "text" : "password";
    }
  }
};
</script>
<style lang="scss" scoped>
.login ::v-deep {
  background-image: url("../../assets/images/login.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  p {
    margin: 0;
    padding: 0;
  }
  .login-input {
    margin-bottom: 0.3rem;
  }
  .van-form {
    width: 6.33rem;
    margin: 0 auto;
  }
  .center-line {
    border-bottom: 1px solid #dcdcdc;
    margin-top: 0.28rem;
    margin-bottom: 0.63rem;
  }
  .bottom-line {
    border-bottom: 1px solid #dcdcdc;
    margin-top: 0.28rem;
  }
  .van-field--error .van-field__control,
  .van-field--error .van-field__control::placeholder {
    color: #cdcddb;
  }
  .van-field__right-icon {
    height: 25px;
  }
  .van-cell {
    background: unset;
    padding: 0;
  }
  .login-title {
    font-size: 0.54rem;
    color: #06aa9d;
    padding-top: 1.5rem;
    padding-left: 0.55rem;
    padding-bottom: 1rem;
  }
  .input-title {
    font-size: 0.32rem;
    color: #404040;
    letter-spacing: 0.83px;
    margin-bottom: 0.31rem;
  }
  .forget-password {
    color: #171717;
    font-size: 0.28rem;
    text-align: right;
  }
  .login-btn {
    margin-top: 0.523rem;
    .van-button--normal {
      font-size: 0.32rem;
    }
  }
  .van-icon-clear {
    z-index: 1000;
  }
}
</style>
